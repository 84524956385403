.flyout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

// Custom colors
.agency-dark {
  background-color: $agencyDark !important;
  &.darken-1 {
    background-color: darken($agencyDark, 5%) !important;
  }
  &.darken-2 {
    background-color: darken($agencyDark, 10%) !important;
  }
  &.darken-3 {
    background-color: darken($agencyDark, 15%) !important;
  }
  &.lighten-1 {
    background-color: lighten($agencyDark, 10%) !important;
  }
  &.lighten-2 {
    background-color: lighten($agencyDark, 20%) !important;
  }
  &.lighten-3 {
    background-color: lighten($agencyDark, 30%) !important;
  }
}
.agency-red {
  background-color: $agencyRed !important;
  &.darken-1 {
    background-color: darken($agencyRed, 10%) !important;
  }
  &.darken-2 {
    background-color: darken($agencyRed, 20%) !important;
  }
  &.darken-3 {
    background-color: darken($agencyRed, 30%) !important;
  }
  &.lighten-1 {
    background-color: lighten($agencyRed, 10%) !important;
  }
  &.lighten-2 {
    background-color: lighten($agencyRed, 20%) !important;
  }
  &.lighten-3 {
    background-color: lighten($agencyRed, 30%) !important;
  }
}

.btn {
  box-shadow: none !important;
  &.runes,
  &.rune {
    text-transform: initial;
    &.btn.btn-lg {
      padding: 0.8rem 1.8rem;
      font-size: 1.3em;
    }
  }
}

// Scrollbar
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
::selection {
  background: #c53c2f; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #c53c2f; /* Gecko Browsers */
}

// Custom pulsating heart
.pulse {
  -webkit-animation: pulse 1s infinite;
  animation: pulse 1s infinite;
}

// Font size fix for runes in h2
h2 {
  &.runes {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 400px) {
  .navbar-brand {
    strong {
      font-size: 1em !important;
    }
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2019-2020 Werbeagentur Christian Aichner
 */

nav {
  .navbar-brand {
    font-size: 1.5em;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
  }
  .nav-link {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2019-2020 Werbeagentur Christian Aichner
 */

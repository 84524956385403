#gallery {
  background-color: black;
  // Checkbox fill color
  .form-check-input[type="checkbox"].filled-in:checked + label:after,
  label.btn input[type="checkbox"].filled-in:checked + label:after {
    border: 2px solid white !important;
    background-color: white !important;
  }
  .form-check-input[type="checkbox"].filled-in:checked + label:before,
  label.btn input[type="checkbox"].filled-in:checked + label:before {
    border-right: 2px solid black;
    border-bottom: 2px solid black;
  }
  .col-md-3 {
    margin-bottom: 1em;
    position: relative;
    height: 100%;

    &.available {
      &::after {
        content: "Verfügbar";
        position: absolute;
        left: auto;
        width: auto;
        right: auto;
        top: 0;
        padding: 0.2rem 0.4em;
        text-align: center;
        background-color: transparentize($color: #00c851, $amount: 0.6);
        border: 1px #00c851 solid;
        display: block;
      }
    }
    &.sold {
      &:after {
        content: "Nicht verfügbar";
        position: absolute;
        left: auto;
        width: auto;
        right: auto;
        top: 0;
        padding: 0.2rem 0.4em;
        text-align: center;
        background-color: transparentize($color: #ff3547, $amount: 0.6);
        border: 1px #ff3547 solid;
        display: block;
      }
    }
  }
  .view {
    cursor: pointer;
    transition: filter 0.5s ease;
    &:hover {
      filter: hue-rotate(50deg) grayscale(100%) contrast(200%);
    }
    &.active {
      filter: hue-rotate(50deg) grayscale(100%) contrast(200%);
    }
  }
  .modal-dialog {
    .close {
      color: red;
      text-shadow: none;
    }
    .modal-content {
      background-color: #2e2e2e !important;
      .table {
        color: #bebebe;
      }
      .table td,
      .table th {
        border-top: 1px solid #4e4e4e;
      }
      .normalize {
        font-size: 1em;
      }
    }
    .modal-footer {
      border-top: 1px solid gray;
    }
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2019-2020 Werbeagentur Christian Aichner
 */

#about {
  background-color: black;
  border-top: 2px #3e3e3e solid;
  border-bottom: 2px #3e3e3e solid;
  h2 {
    margin-bottom: 0;
  }
  img {
    max-width: 250px;
    margin-top: 3em;
    margin-bottom: 1em;
  }
  p {
    padding-bottom: 0.8em;
  }
}

/** 
* SPDX-License-Identifier: (EUPL-1.2)
* Copyright © 2019-2020 Werbeagentur Christian Aichner
*/

#intro {
  .view {
    height: 100vh;
    background-color: #151617;
    color: white;
    img {
      max-width: 80%;
      height: 15rem;
      display: block;
      margin: auto;
    }
    h5 {
      font-size: 2.5em;
    }
  }
  .btn-scroll {
    position: absolute;
    bottom: 4rem;
    left: auto;
    right: auto;
    width: 100px;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
  }

  @media only screen and (max-width: 400px) {
    img {
      max-width: 100px !important;
      height: auto !important;
    }
    h5 {
      font-size: 2em !important;
      padding-bottom: 1em !important;
    }
  }

  .btn {
    display: inline;
    color: black;
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2019-2020 Werbeagentur Christian Aichner
 */

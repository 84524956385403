@font-face {
  font-family: "norse";
  src: url("./fonts/norse-webfont.woff2") format("woff2"),
    url("./fonts/norse-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "norsebold";
  src: url("./fonts/norsebold-webfont.woff2") format("woff2"),
    url("./fonts/norsebold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "runes";
  src: url("./fonts/futharkrunes-webfont.woff2") format("woff2"),
    url("./fonts/futharkrunes-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.runes {
  font-family: "norse";
  font-size: 1.4em;
  &.font-weight-bold {
    font-family: "norsebold";
  }
}

.rune {
  font-family: "runes";
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2019-2020 Werbeagentur Christian Aichner
 */
